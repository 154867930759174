import axios from './axios';
import API_URL from './api_url'

export const readTempPatient = (params) => {

    return axios.get(`${API_URL.TEMPPATIENT_URL}/${params._id}`)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const retrieveTempPatients = (params) => {
    
    return axios.get(`${API_URL.TEMPPATIENT_URL}`, {params: params})
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const approveTempPatient = (params) => {
    
    return axios.post(`${API_URL.TEMPPATIENT_APPROVE_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const deleteTempPatient = (params) => {
    
    return axios.delete(`${API_URL.TEMPPATIENT_URL}`, {params})
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const approveAllTempPatients = (params) => {
    
    return axios.post(`${API_URL.TEMPPATIENT_ALL_APPROVE_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const deleteAllTempPatients = (params) => {
    
    return axios.delete(`${API_URL.TEMPPATIENT_ALL_DELETE_URL}`, {params})
        .then(data => data.data)
        .catch(error => {throw error.response});
};
