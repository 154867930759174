import React from 'react';
import { connect } from 'react-redux';
import store from 'store'
import {
    showMessage,
    setUser,
    handleHTTPError
} from 'store/actions';
import { login } from 'apis';
import { utils} from 'utils';
import TextBox from 'components/form/textbox'
import LoadingButton from 'components/form/loaddingbutton'
import ForgotPassDlg from 'components/forgot_pass_dlg'
import './index.scss'


class Login extends React.Component {

    constructor () {

        super ();

        this.state = {

            email: '',

            password: '',

            isOpen: false,

            validate: false,

            loading: false,
        }
    }


    componentDidMount = () => {}


    login = () => {

        if (this.state.loading) return;

        this.setState({validate: true});

        if (!this.state.email|| !utils.validateEmail(this.state.email) || !this.state.password) return;
        
        const params = {

            email: this.state.email,

            password: this.state.password,
        }

        this.setState({loading: true})

        login(params).then(data => {

            this.setState({ loading: false})
            
            this.props.setUser(data.data);

            store.set('token', data.token)

            this.props.history.push('/')
                
        }).catch(error => {

            this.setState({ loading: false})

            this.props.handleHTTPError(error)
        })
    }


    toggle = () => {

        this.setState({ isOpen: !this.state.isOpen})
    }

    render () {
        
        return (

            <div  className="signin">
                <div className="content">
                    <div className="signin-form shadow-md">
                        <img src="/logo.png" alt="logo"/>
                        <h6 className="text-primary text-center">(County Admin)</h6>
                        <TextBox tabIndex="0" id="email" name="email" type='text' 
                            labelHTML="Email"
                            className="mb-4"
                            validate = {this.state.validate} value={this.state.email} placeholder="Email"
                            onBlur={(e)=>{}}
                            onChange={(email, e) => {
                                this.setState({ email: email })
                            }}
                            validationOption={{
                                showMsg: false,
                                name: 'Email',
                                reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                regMsg: 'Invalid Email',
                                check: true,
                                required: true, 
                            }}
                        />
                        <TextBox tabIndex="0" id="password" name="password" type='password' 
                            labelHTML="Password"
                            className="mb-4"
                            validate = {this.state.validate} value={this.state.password} placeholder="Password"
                            onBlur={(e)=>{}}
                            onChange={(password, e) => {
                                this.setState({ password: password })
                            }}
                            validationOption={{
                                showMsg: false,
                                name: 'Password',
                                check: true,
                                required: true, 
                            }}
                        />
                        <LoadingButton loading={this.state.loading} onClick={this.login} className="w-100 mb-4 primary">LOGIN</LoadingButton>
                        <p className="text-primary pointer text-center label" onClick={this.toggle}>Forgot Password?</p>
                        <ForgotPassDlg
                            isOpen={this.state.isOpen}
                            toggle={this.toggle}
                        />
                    </div>
                </div>
            </div>
        );
    }
}


export default connect (null, {

    showMessage,

    setUser,

    handleHTTPError

}) (Login);
