export default {

    BASE_URL: `${process.env.REACT_APP_SERVER_URL}/api`,

    // county
    COUNTY_URL: 'county',
    COUNTY_LOGIN_URL: 'county/login',
    COUNTY_ANALYSYS_URL: 'county/analysis',
    COUNTY_CHANGEPASS_URL: 'county/change-password',
    COUNTY_FORGOTPASS_URL: 'county/forgot-password',
    COUNTY_RESETPASS_URL: 'county/reset-password',

    COUNTY_SETTING_URL: 'county-settings',
    COUNTY_REMOVE_ZIPCODE_URL: 'county-settings/delete-zipcode',

    PATIENT_URL: 'patients',
    PATIENT_CSVFILE_UPLOAD_URL: 'patients/upload-csv',

    TEMPPATIENT_URL: 'temp-patients',
    TEMPPATIENT_APPROVE_URL: 'temp-patients/approve',
    TEMPPATIENT_ALL_APPROVE_URL: 'temp-patients/approve-all',
    TEMPPATIENT_ALL_DELETE_URL: 'temp-patients/all',
    

    PATIENT_GROUP_URL: 'patient-groups',

    LOCATION_URL: 'vac-locations',

    LOCATION_ANALYSYS_URL: 'vac-locations/analysis',

    LOCATION_SETTING_URL: 'vacLocation-settings',

    REQTEMPLATE_URL: 'reqtemplates',

    ADMIN_MSG_URL: 'admin-messages',

    REQUEST_URL: 'requests',
    REQUEST_CREATE_BYPLAN_URL: 'requests/create-byplan',
    REQUEST_WAITLIST_URL: 'requests/waitlist',

    SCHEDULE_URL: 'schedules',
    SCHEDULE_PUBLISH_URL: 'schedules/publish',
    SCHEDULE_ISVALIDPATIENT_URL: 'schedules/is-valid-patient',

    TEMP_SCHEDULE_URL: 'temp-schedules',
    TEMP_SCHEDULE_GENERATE_URL: 'temp-schedules/generate',
    TEMP_SCHEDULE_ISVALIDPATIENT_URL: 'temp-schedules/is-valid-patient',

    PLAN_URL: 'plans',

    POST_URL: 'posts',

    COMMENT_URL: 'comments'
}

