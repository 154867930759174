import API_URL from './api_url'
import axios from './axios'

export const login = (params) => {

    return axios.post(`${API_URL.COUNTY_LOGIN_URL}`, params)
    .then(data => data.data)
    .catch(error => {throw error.response});
};

export const updateProfile = (params) => {
    
    return axios.patch(`${API_URL.COUNTY_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const changePassword = (params) => {

    return axios.post(`${API_URL.COUNTY_CHANGEPASS_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const forgotPassword = (params) => {

    return axios.post(`${API_URL.COUNTY_FORGOTPASS_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const resetPassword = (params) => {

    return axios.post(`${API_URL.COUNTY_RESETPASS_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const countyAnalysis = (params) => {

    return axios.get(`${API_URL.COUNTY_ANALYSYS_URL}`, {params: params})
        .then(data => data.data)
        .catch(error => {throw error.response});
};