import React from 'react';
import { Container, Row, Col } from 'reactstrap'
import './index.scss'


class PageLayout extends React.Component {

    render() {
        return (
            <Container className={`${this.props.className} page-layout p-md-5 p-2`} fluid={true}>
                <Row>
                    <Col xs={12} className={`d-flex justify-content-between align-items-center mb-md-4 mb-3 ${this.props.titleClassName}`}>
                        <h3 className="text-gray-800 mb-0 flex-shrink-0 title">{this.props.title}</h3>
                        {this.props.description}
                        {this.props.controls}
                    </Col>
                    <Col xs={12}>{this.props.children}</Col>
                </Row>
            </Container>
        );
    }
}

export default PageLayout