import axios from './axios';
import API_URL from './api_url'


export const createPatientGroup = (params) => {

    return axios.post(`${API_URL.PATIENT_GROUP_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const updatePatientGroup = (params) => {
    
    return axios.patch(`${API_URL.PATIENT_GROUP_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const deletePatientGroup = (params) => {
    
    return axios.delete(`${API_URL.PATIENT_GROUP_URL}`, {params: params})
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const readPatientGroup = (params) => {
    return axios.get(`${API_URL.PATIENT_GROUP_URL}/${params._id}`)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const retrievePatientGroups = (params) => {
    
    return axios.get(`${API_URL.PATIENT_GROUP_URL}`, {params})
        .then(data => data.data)
        .catch(error => {throw error.response});
};