import React from 'react';
import {connect} from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    logout
} from 'store/actions'

const ROUTE_TYPE = {
    COUNTY: 'county',
    LOCATION: 'vacLocation'
}

class UserInfo extends React.Component {


    render() {

        const getRouteType = () => {

            if (/^[/](vac-locations)[/][A-Z0-9]{5}([/\w.-]*)*\/?$/.test(this.props.location.pathname)) return ROUTE_TYPE.LOCATION
    
            return ROUTE_TYPE.COUNTY
        }

        const renderCountyInfo = () => {

            return <>
                <div className="user-profile">
                    <h6 className="user-name mb-0">{this.props.user.name}</h6>
                </div>
                <div className="dropdown-item user-select-none pointer" onClick={(e) => {
                    this.props.history.push('/account')
                }}> <FontAwesomeIcon icon="user"/> &nbsp;&nbsp;&nbsp;Account
                </div>
                <div className="dropdown-item user-select-none pointer" onClick={this.props.logout}>
                    <FontAwesomeIcon icon="sign-out-alt"/> &nbsp;&nbsp;&nbsp;Logout
                </div>
            </>
        }

        const renderLocationInfo = () => {

            if (!this.props.vacLocation) return null;

            return <>
                <div className="user-profile">
                    <h6 className="user-name mb-0">{this.props.vacLocation.name}</h6>
                </div>
                <div className="dropdown-item user-select-none pointer" onClick={(e) => {
                    this.props.history.push(`/vac-locations/${this.props.vacLocation.ID}/manage`)
                }}> <FontAwesomeIcon icon="user"/> &nbsp;&nbsp;&nbsp;Profile
                </div>
                <div className="dropdown-item user-select-none pointer" onClick={this.props.logout}>
                    <FontAwesomeIcon icon="sign-out-alt"/> &nbsp;&nbsp;&nbsp;Logout
                </div>
            </>
        }
        
        return (
            <> { getRouteType() === ROUTE_TYPE.COUNTY ? renderCountyInfo(): renderLocationInfo() } </>
        );
    }
}

const mapStateToProps = (reducer) => {

    const { user, vacLocation } = reducer;

    return { user, vacLocation };
}


export default withRouter(connect(mapStateToProps, {

    logout

})(UserInfo));


