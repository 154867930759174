import axios from './axios';
import API_URL from './api_url'

export const createPatient = (params) => {
    
    return axios.post(`${API_URL.PATIENT_URL}/register`, params )
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const readPatient = (params) => {

    return axios.get(`${API_URL.PATIENT_URL}/${params._id}`)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const updatePatient = (params) => {

    return axios.patch(`${API_URL.PATIENT_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const retrievePatients = (params) => {
    
    return axios.get(`${API_URL.PATIENT_URL}`, {params: params})
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const uploadPatientsCSV = (params) => {
    
    const headers = {
        'content-type': 'multipart/form-data',
        timeout: 300000
    }
    const formData = new FormData();
    formData.set('file', params.file)
    formData.set('fvDateOfCSV', params.fvDateOfCSV)

    return axios.post(`${API_URL.PATIENT_CSVFILE_UPLOAD_URL}`, formData, {headers: headers, onUploadProgress: params.onUploadProgress})
        .then(data => data.data)
        .catch(error => {throw error.response});
};

