import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import { Button } from 'reactstrap'
import Sidebar from "react-sidebar";
import CustomScrollbars from 'components/custom_scrollbar';
import SidebarCountyContent from './SidebarCountyContent';
import SidebarLocationContent from './SidebarLocationContent';
import SidebarLogo from './SidebarLogo';
import {
    toggleSidebar, 
    setWindowWidth
} from 'store/actions';
import './index.scss'


const ROUTE_TYPE = {
    COUNTY: 'county',
    LOCATION: 'location'
}

class SideBar extends React.PureComponent {

    constructor(props) {

        super(props);
        
        this.state = {}
    }

    componentDidMount() {

        window.addEventListener('resize', () => {

            this.props.setWindowWidth(window.innerWidth)
        });
    }

    
    render() {

        const getRouteType = () => {

            if (/^[/](vac-locations)[/][A-Z0-9]{5}([/\w.-]*)*\/?$/.test(this.props.location.pathname)) return ROUTE_TYPE.LOCATION
    
            return ROUTE_TYPE.COUNTY
        }

        return (
            <Sidebar
                sidebarClassName="app-sidebar"
                sidebar={<div className="side-nav">
                    <SidebarLogo/>
                    <CustomScrollbars className={`scrollbar ${getRouteType() === ROUTE_TYPE.COUNTY ? '' : 'vac-location'}`}>
                        {
                            getRouteType() === ROUTE_TYPE.COUNTY ?
                            <SidebarCountyContent/>:
                            <SidebarLocationContent/>
                        }
                    </CustomScrollbars>
                    {
                        getRouteType() === ROUTE_TYPE.LOCATION &&
                        <div className="w-100 px-2 mt-3">
                            <Button color="primary" style={{height: '50px', fontSize: '14px'}} className="btn-block" onClick={()=>{
                                this.props.history.push('/vac-locations')
                            }}>Go to County</Button>
                        </div>
                    }
                </div>}
                open={this.props.openSidebar}
                onSetOpen={()=>this.props.toggleSidebar(!this.props.openSidebar)}
                styles={{ sidebar: { background: "white" } }}
                docked={this.props.width > 1200}
                transitions={true}
            ><div></div></Sidebar>
        );
    }
}


const mapStateToProps = (reducer) => {

    const {openSidebar, width, user} = reducer;

    return {openSidebar, width, user}
};


export default withRouter(connect(mapStateToProps, {

    toggleSidebar, 

    setWindowWidth

})(SideBar));
