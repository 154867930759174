import axios from 'axios';
import API_URL from './api_url'


export const retrieveAdminMsgs = (params) => {
    
    return axios.get(`${API_URL.ADMIN_MSG_URL}`, {params: params})
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const readAdminMsg = (params) => {
    
    return axios.get(`${API_URL.ADMIN_MSG_URL}/${params._id}`)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const createAdminMsg = (params) => {
    
    return axios.post(`${API_URL.ADMIN_MSG_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const updateAdminMsg = (params) => {
    
    return axios.patch(`${API_URL.ADMIN_MSG_URL}`, params )
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const deleteAdminMsg = (params) => {
    
    return axios.delete(`${API_URL.ADMIN_MSG_URL}`, {params: params})
        .then(data => data.data)
        .catch(error => {throw error.response});
};