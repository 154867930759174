import axios from 'axios';
import API_URL from './api_url'


export const retrieveReqTemplates = (params) => {
    
    return axios.get(`${API_URL.REQTEMPLATE_URL}`, {params: params})
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const readReqTemplate = (params) => {
    
    return axios.get(`${API_URL.REQTEMPLATE_URL}/${params._id}`)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const createReqTemplate = (params) => {
    
    return axios.post(`${API_URL.REQTEMPLATE_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const updateReqTemplate = (params) => {
    
    return axios.patch(`${API_URL.REQTEMPLATE_URL}`, params )
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const deleteReqTemplate = (params) => {
    
    return axios.delete(`${API_URL.REQTEMPLATE_URL}`, {params: params})
        .then(data => data.data)
        .catch(error => {throw error.response});
};