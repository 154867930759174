import React from 'react';
import { connect } from 'react-redux'
import { Route, Redirect, Switch, withRouter} from 'react-router-dom';
import swal from 'sweetalert'
import loadable from '@loadable/component'
import Layout from 'components/layout';
import { retrievePatientGroups } from 'apis'
import { 
    setPatientGroups, 
    handleHTTPError,
    setEmergencyErrors
} from 'store/actions'

const Dashboard = loadable(() => import('./dashboard'));
const Patients = loadable(() => import('./patients'));
const Settings = loadable(() => import('./settings'));
const VacLocations = loadable(() => import('./vac_locations'));
const AdminMessages = loadable(() => import('./admin_messages'));
const Account = loadable(() => import('./account'));
const Posts = loadable(() => import('./posts'));


class PrivateRoutes extends React.Component {

    constructor(props){

        super(props);

        this.state = { }
    }


    componentDidMount = () => {

        retrievePatientGroups().then(async(data) => {            
                
            this.props.setPatientGroups(data.data)

            if (data.data.length === 0) {

                await swal({

                    title: "Important",
        
                    text: "You need to set up patient groups first",
        
                    icon: "warning",
                });
    
                return this.props.history.push('/settings/patient_groups')
            }
          
        }).catch(error => {

            this.props.handleHTTPError(error)
        })
    }

    
    render() {

        return (
            <Layout>
                <Switch>
                    <Route path='/dashboard' component={Dashboard}/> 
                    <Route path='/patients' component={Patients}/>
                    <Route path='/settings' component={Settings}/>
                    <Route path='/vac-locations' component={VacLocations}/> 
                    <Route path='/admin-messages' component={AdminMessages}/> 
                    <Route path='/posts' component={Posts}/> 
                    <Route path='/account' component={Account}/> 
                    <Redirect path='/' to={'/dashboard'}/> 
                </Switch>
            </Layout>
        );
    }
}

const mapStateToProps = (reducer) => {

    const {patientGroups, emergencyErrors} = reducer;

    return { patientGroups, emergencyErrors }
}


export default withRouter(connect(mapStateToProps, {

    setPatientGroups,

    handleHTTPError, 

    setEmergencyErrors

})(PrivateRoutes));