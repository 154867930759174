import {
    SET_EMERGENCY_ERRORS,
    HTTP_401_ERROR,
	HTTP_OTHER_ERROR,
    SHOW_MESSAGE,
    TOGGLE_SIDEBAR,
    WINDOW_WIDTH,
    SET_USER,
	LOGOUT,
    SET_PATIENT_SEARCHPARAMS,
	SET_PATIENT_GROUPS,
	SET_LOCATION_SETTINGS,
    SET_LOCATION,
    SET_DATETIMESETTINGS,
} from '../actionTypes';

import store from 'store'

const INIT_STATE = {

    emergencyErrors: [],

    /** ui */
    openSidebar: false,
    width: window.innerWidth,
    message: null,
    showMsg: false,

    /** county */
    user: store.get('user') || null,
    patientGroups: [],
    patientSearchParams: null,

    /** vacLocation */
    vacLocation: null,
    locationSetting: {},

    /** template */
    dateTimeSettings: store.get('dateTimeSettings'),
};



export default (state = INIT_STATE, action) => {

    switch (action.type) {

        /** emergency errors */

        case SET_EMERGENCY_ERRORS:
			
            return { ...state, emergencyErrors: action.payload };

        /** http error */

        case HTTP_401_ERROR:

			store.clearAll();
            window.location.href = "/signin"
            return { ...state };

		case HTTP_OTHER_ERROR:

            return { ...state, showMsg: true, message: { type: 'error', text: action.payload.data ? action.payload.data.message || 'Unknow Error' : String(action.payload)}}
        

        /** UI */

		case SHOW_MESSAGE:

            return { ...state, showMsg: action.payload.showMsg, message: action.payload.message}

        case TOGGLE_SIDEBAR:

            return { ...state, openSidebar: action.payload };

        case WINDOW_WIDTH: 

            return { ...state, width: action.payload };


        /** county */
        case LOGOUT: 

            store.clearAll()
            return { ...state, user: null }

        case SET_USER: 

            store.set('user', action.payload)
            return { ...state, user: action.payload }

        case SET_PATIENT_SEARCHPARAMS: 

            return { ...state, patientSearchParams: action.payload }
        

        case SET_PATIENT_GROUPS: 

            let newEmergencyErrors = [...state.emergencyErrors]

            if (action.payload.some(ut => ut.isDefault)) {

                newEmergencyErrors = newEmergencyErrors.filter(e => e.name !== 'Patient group setting')
            }else{

                newEmergencyErrors = newEmergencyErrors.filter(e => e.name !== 'Patient group setting')

                newEmergencyErrors.push({

                    name: 'Patient group setting',

                    description: 'There is no default patient group in system and user register feature has been desabled due to this.',

                    resolveUrl: '/settings/patient_groups'
                })
            }

            return { ...state, patientGroups: action.payload, emergencyErrors: newEmergencyErrors }
        
        case SET_DATETIMESETTINGS: 

            store.set('dateTimeSettings', action.payload)
            return { ...state, dateTimeSettings: action.payload }

        case SET_LOCATION: 

            return { ...state, vacLocation: action.payload}

        case SET_LOCATION_SETTINGS: 

            return { ...state, locationSetting: action.payload }
        
        default:

            return state;
    }
}
