import React, { Component } from 'react'
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


const ROUTE_TYPE = {
    COUNTY: 'county',
    LOCATION: 'vacLocation'
}


class SidebarLogo extends Component {

    constructor(props) {

        super(props)
        
        this.state = {}
    }
    

    render() {

        const getRouteType = () => {

            if (/^[/](vac-locations)[/][A-Z0-9]{5}([/\w.-]*)*\/?$/.test(this.props.location.pathname)) return ROUTE_TYPE.LOCATION
    
            return ROUTE_TYPE.COUNTY
        }


        return (
            <div className="sidebar-logo pointer py-4">
                <img src="/logo.png"  alt="" onClick={() => {this.props.history.push('/')}}/>
                {
                    getRouteType() === ROUTE_TYPE.COUNTY ?
                    <p className="p-0 m-0 mt-2">(County Admin)</p>:
                    <p className="p-0 m-0 mt-2">({this.props.vacLocation? this.props.vacLocation.name : 'Locaion Admin'})</p>
                }
            </div>
        )
    }
}

const mapStateToProps = (reducer) => {

    const { user, vacLocation } = reducer;

    return { user, vacLocation }
};


export default withRouter(connect(mapStateToProps, {})(SidebarLogo));
