import axios from './axios';
import API_URL from './api_url'



export const readLocationSetting = (params) => {
    
    return axios.get(`${API_URL.LOCATION_SETTING_URL}/${params._id}`)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const updateLocationSetting = (params) => {
    
    return axios.patch(`${API_URL.LOCATION_SETTING_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

