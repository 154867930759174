import axios from './axios';
import API_URL from './api_url'

export const updatePlan = (params) => {
    
    return axios.patch(`${API_URL.PLAN_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const readPlan = (params) => {
    return axios.get(`${API_URL.PLAN_URL}/${params.vacLocation}`)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const retrievePlans = (params) => {
    
    return axios.get(`${API_URL.PLAN_URL}`, {params})
        .then(data => data.data)
        .catch(error => {throw error.response});
};