import axios from 'axios';
import API_URL from './api_url'


export const readCountySetting = (params) => {
    
    return axios.get(`${API_URL.COUNTY_SETTING_URL}/${params._id}`)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const updateCountySetting = (params) => {
    
    return axios.patch(`${API_URL.COUNTY_SETTING_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const deleteCountyZipcode = (params) => {
    
    return axios.post(`${API_URL.COUNTY_REMOVE_ZIPCODE_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};
