import axios from './axios';
import API_URL from './api_url'


export const createLocation = (params) => {

    return axios.post(`${API_URL.LOCATION_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const updateLocation = (params) => {
    return axios.patch(`${API_URL.LOCATION_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const readLocation = (params) => {

    return axios.get(`${API_URL.LOCATION_URL}/${params._id}`)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const retrieveLocations = (params) => {

    return axios.get(`${API_URL.LOCATION_URL}`, { params})
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const deleteLocation = (params) => {
    
    return axios.delete(`${API_URL.LOCATION_URL}`, { params })
        .then(data => data.data)
        .catch(error => {throw error.response});
};


export const getLocationAnalysisData = (params) => {

    return axios.get(`${API_URL.LOCATION_ANALYSYS_URL}`, {params})
        .then(data => data.data)
        .catch(error => {throw error.response});
};