import React from 'react';
import { connect } from 'react-redux';
import {
    showMessage,
    handleHTTPError
} from 'store/actions';
import { resetPassword } from 'apis';
import TextBox from 'components/form/textbox'
import LoadingButton from 'components/form/loaddingbutton'
import './index.scss'


class ResetPass extends React.Component {

    constructor () {

        super ();

        this.state = {

            password: '',

            token: '',

            validate: false,

            loading: false,
        }
    }


    componentDidMount = () => {

        const token = this.props.match.params.token;

        if (!token) {

            this.props.showMessage({showMsg: true, message: { type: 'warning', text: 'Invalid URL'}})

            this.props.history.push('/login')
        }

        this.setState({token})
    }


    resetPass = () => {

        if (this.state.loading) return;

        this.setState({validate: true});

        if (!this.state.password) return;
        
        const params = {

            password: this.state.password,

            token: this.state.token
        }

        this.setState({loading: true})

        resetPassword(params).then(data => {

            this.setState({ loading: false})

            this.props.showMessage({showMsg: true, message: { type: 'success', text: data.message}})

            this.props.history.push('/login')
                
        }).catch(error => {

            this.setState({ loading: false})

            this.props.handleHTTPError(error)
        })
    }


    goLogin = () => {

        this.props.history.push('/login')
    }

    render () {
        
        return (

            <div  className="resetpass">
                <div className="content">
                    <div className="resetpass-form shadow-md">
                        <img src="/logo.png" alt="logo"/>
                        <h3 className="text-primary text-center">Reset Password</h3>
                        <h6 className="text-primary text-center">(County Admin)</h6>
                        <TextBox tabIndex="0" id="password" name="password" type='password' 
                            labelHTML="New Password"
                            className="mb-4"
                            validate = {this.state.validate} value={this.state.password} placeholder="Password"
                            onBlur={(e)=>{}}
                            onChange={(password, e) => {
                                this.setState({ password: password })
                            }}
                            validationOption={{
                                showMsg: false,
                                name: 'Password',
                                check: true,
                                required: true, 
                            }}
                        />
                        <LoadingButton loading={this.state.loading} onClick={this.resetPass} className="w-100 mb-4 primary">RESET PASSWORD</LoadingButton>
                        <p className="text-primary pointer text-center label" onClick={this.goLogin}>Back Login?</p>
                    </div>
                </div>
            </div>
        );
    }
}


export default connect (null, {

    showMessage,

    handleHTTPError

}) (ResetPass);