import React, { Component } from 'react'
import { connect } from 'react-redux'
import {Modal, ModalBody, ModalHeader, ModalFooter, Button} from 'reactstrap'
import toast from 'cogo-toast'
import TextBox from 'components/form/textbox'
import {
    showMessage,
    handleHTTPError,
} from 'store/actions'
import {
    forgotPassword
} from 'apis'


class ForgotPassDlg extends Component {


    constructor(props){

        super(props);

        this.state = {

            email: '',

            loading: false,
            
            validate: false
        }
    }


    forgotpass = () => {

        const {hide} = toast.loading('Please wait...', {hideAfter: 0})

        const params = {

            email: this.state.email
        }

        forgotPassword(params).then(data => {

            hide();

            this.props.showMessage({ showMsg: true, message: { type: 'success', text: data.message}})

            this.cancel()

        }).catch(error => {

            hide();

            this.props.handleHTTPError(error)
        })
    }


    cancel = () => {

        this.setState({ email: ''})

        this.props.toggle()
    }
    

    render() {

        return (        
            
            <Modal isOpen={this.props.isOpen} toggle={this.cancel}>
                <ModalHeader toggle={this.cancel}>Forgot Password</ModalHeader>
                <ModalBody>
                    <TextBox tabIndex="0" id="email" name="email" type='text' 
                        labelHTML="County Email"
                        value = {this.state.email} placeholder="florida@mail.com"
                        validate={this.state.validate}
                        onChange={(email, e) => {
                            this.setState({email})
                        }}
                        validationOption={{
                            showMsg: false,
                            reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            regMsg: 'Invalid Email',
                            check: true,
                            required: true, 
                        }}
                    />
                </ModalBody>
                <ModalFooter>
                    <div className="w-100 d-flex justify-content-lg-between">
                        <Button color="primary" outline className="standard"  onClick={this.cancel}>Cancel</Button>
                        <Button color="primary" className="standard"  onClick={this.forgotpass}>Forgot</Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }
}


export default connect(null, {

    showMessage,

    handleHTTPError,

})(ForgotPassDlg)


