import React, { Component} from 'react';
import Header from './header';
import Sidebar from './sidebar';
import PageLayout from './page_layout'
import EmergencyAlert from './emergency_alert'

import './index.scss'


class Layout extends Component {

    constructor(props){

        super(props);

        this.state = { }
    }

    
    render() {

        return (
            <>
                <Sidebar/>
                <Header/>
                <div id="route-content" className="position-relative">
                    <EmergencyAlert/>
                    {this.props.children}
                </div>
            </>
        );
    }
}

export { Layout as default, PageLayout}