import axios from './axios';
import API_URL from './api_url'

export const createSchedule =  (params) => {
    
    return axios.post(`${API_URL.SCHEDULE_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const updateSchedule =  (params) => {

    return axios.patch(`${API_URL.SCHEDULE_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const retrieveSchedules = (params) => {

    return axios.get(`${API_URL.SCHEDULE_URL}`, {params: params})
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const readSchedule = (params) => {

    return axios.get(`${API_URL.SCHEDULE_URL}/${params._id}`)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const publishSchedules =  (params) => {
    
    return axios.post(`${API_URL.SCHEDULE_PUBLISH_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const isSchedValidePatient =  (params) => {
    
    return axios.post(`${API_URL.SCHEDULE_ISVALIDPATIENT_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const runScheduleCancelRoutine =  (params) => {
    
    return axios.post(`${API_URL.RUN_SCHEDULECANCELROUTINE_URL}`, params)
    .then(data => data.data)
    .catch(error => {
        throw error.response 
    });
};




