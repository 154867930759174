import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import toast from 'cogo-toast';
import { showMessage } from 'store/actions';
import PrivateRoutes from './private_routes'
import Login from './public_routes/login'
import ResetPass from './public_routes/reset_pass'

const RestrictedRoute = ({component: Component, user, ...rest}) =>
    <Route
        {...rest}
        render={props =>
            user
                ? <Component {...props} />
                : <Redirect
                    to={{
                        pathname: '/login',
                        state: {from: props.vacLocation}
                    }}
                />}
    />;

class Routes extends React.Component {

    componentDidUpdate = (prevProps) => {

		if (!prevProps.showMsg && this.props.showMsg) {

			if (this.props.message !== null) {

				if (this.props.message.type === 'success') {

					return toast.success(this.props.message.text, { position: 'top-right', heading: 'Success' }).then(() => { 

                        this.props.showMessage({ showMsg: false, message: null}) 
                    })

				}else if (this.props.message.type === 'warning') {

					return toast.warn(this.props.message.text, { position: 'top-right', heading: 'Warning' }).then(() => { 
                        
                        this.props.showMessage({ showMsg: false, message: null}) 
                    })

				}else {

					return toast.error(this.props.message.text, { position: 'top-right', heading: 'Error' }).then(() => { 
                        
                        this.props.showMessage({ showMsg: false, message: null}) 
                    })
				}
			}
		}
	}

    render() {


        const {match, user} = this.props;
        
        return (
           <Switch>
               <Route path='/login' component={Login}/>
               <Route path='/resetpass/:token' component={ResetPass}/>
               <RestrictedRoute path={`${match.url}`} user={user} component={PrivateRoutes}/>
           </Switch>
        );
    }
}


const mapStateToProps = (reducer) => {

    const { user, showMsg, message } = reducer;

    return { user, showMsg, message };
};

export default connect(mapStateToProps, { showMessage })(Routes);