import axios from './axios';
import API_URL from './api_url'

export const createTempSchedule =  (params) => {
    
    return axios.post(`${API_URL.TEMP_SCHEDULE_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const updateTempSchedule =  (params) => {

    return axios.patch(`${API_URL.TEMP_SCHEDULE_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const retrieveTempSchedules = (params) => {

    return axios.get(`${API_URL.TEMP_SCHEDULE_URL}`, {params: params})
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const readTempSchedule = (params) => {

    return axios.get(`${API_URL.TEMP_SCHEDULE_URL}/${params._id}`)
        .then(data => data.data)
        .catch(error => {throw error.response});
};

export const generateTempSchedules = (params) => {

    return axios.post(`${API_URL.TEMP_SCHEDULE_GENERATE_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};


export const isTempSchedValidePatient =  (params) => {
    
    return axios.post(`${API_URL.TEMP_SCHEDULE_ISVALIDPATIENT_URL}`, params)
        .then(data => data.data)
        .catch(error => {throw error.response});
};




