import React, { Component } from 'react'
import { connect} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {
    setEmergencyErrors
} from 'store/actions'
import './index.scss'

class EmergencyAlert extends Component {
    
    render() {

        if (this.props.emergencyErrors.length === 0) return null;

        const displayEmergencyErrors = () => {

            return this.props.emergencyErrors.map((error, index) => {
                return <div className={(index < this.props.emergencyErrors.length -1 ? 'mb-3' : '')}key={index}>
                    <p className="mb-1">{index + 1}. {error.name}</p>
                    <small>{error.description} Plase resolve <span className="text-primary pointer" onClick={()=>{this.props.history.push(error.resolveUrl)}}>here</span></small>
                </div>

            })
        }

        return (
            <div id='emergency-alert'>
                {displayEmergencyErrors()}
            </div>
        )
    }
}

const mapStateToProps = (reducer) => {

    const { emergencyErrors } = reducer

    return {emergencyErrors}
}

export default withRouter(connect(mapStateToProps, {

    setEmergencyErrors

})(EmergencyAlert))
