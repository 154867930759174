
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {NavLink, withRouter} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


class SidebarLocationContent extends Component {


    componentDidMount() {

        this.initMenu()
    }


    componentDidUpdate = (prevProps) => {

        if (!prevProps.vacLocation && this.props.vacLocation) {

            this.initMenu();
        }
    }

    initMenu = () => {

        const menuLi = document.getElementsByClassName('sub-menu-title');

        for (let i = 0; i < menuLi.length; i++) {

            menuLi[i].onclick = function (event) {

                this.parentElement.classList.toggle('open');
            }
        }
    }

    
    render() {

        if (!this.props.vacLocation) return null;

        return (
            <>
                <ul className="nav-menu">
                    <li className="menu no-arrow">
                        <NavLink to={`/vac-locations/${this.props.vacLocation.ID}/dashboard`}>
                            <FontAwesomeIcon icon="chart-area" className="mr-3"/>
                            <span className="nav-text">Dashboard</span>
                        </NavLink>
                    </li>
                    
                    <li className="ui_tooltip menu">
                        <a role="button" href='null' onClick={e => e.preventDefault()} className="sub-menu-title">
                            <FontAwesomeIcon icon="mail-bulk" className="mr-3"/>
                            <span className="nav-text">Request</span>
                        </a>
                        <ul className="sub-menu">
                            <li>
                                <NavLink className="prepend-icon" to={`/vac-locations/${this.props.vacLocation.ID}/requests/templates`}>
                                    <FontAwesomeIcon icon="clipboard-list" className="mr-3"/>
                                    <span className="nav-text">Template</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to={`/vac-locations/${this.props.vacLocation.ID}/requests/details`}>
                                    <FontAwesomeIcon icon="calendar-alt" className="mr-3"/>
                                    <span className="nav-text">Details</span>
                                </NavLink>
                            </li>
                        </ul>
                    </li>
                    <li className="menu no-arrow">
                        <NavLink to={`/vac-locations/${this.props.vacLocation.ID}/plan`}>
                            <FontAwesomeIcon icon="cog" className="mr-3"/>
                            <span className="nav-text">Plan</span>
                        </NavLink>
                    </li>
                    <li className="ui_tooltip menu">
                        <a role="button" href='null' onClick={e => e.preventDefault()} className="sub-menu-title">
                            <FontAwesomeIcon icon="tasks" className="mr-3"/>
                            <span className="nav-text">Schedule</span>
                        </a>
                        <ul className="sub-menu">
                            {/* {
                                !this.props.autoSchedMacroSettings.isAllow && */}
                                <li>
                                    <NavLink className="prepend-icon" to={`/vac-locations/${this.props.vacLocation.ID}/schedules/create`}>
                                        <FontAwesomeIcon icon="cogs" className="mr-3"/>
                                        <span className="nav-text">Create</span>
                                    </NavLink>
                                </li>
                            {/* } */}
                            <li>
                                <NavLink className="prepend-icon" to={`/vac-locations/${this.props.vacLocation.ID}/schedules/manage`}>
                                    <FontAwesomeIcon icon="calendar-alt" className="mr-3"/>
                                    <span className="nav-text">Manage</span>
                                </NavLink>
                            </li>
                        </ul>
                    </li>
                    <li className="ui_tooltip menu">
                        <a role="button" href='null' onClick={e => e.preventDefault()} className="sub-menu-title">
                            <FontAwesomeIcon icon="cogs" className="mr-3"/>
                            <span className="nav-text">Settings</span>
                        </a>
                        <ul className="sub-menu">
                            <li>
                                <NavLink className="prepend-icon" to={`/vac-locations/${this.props.vacLocation.ID}/settings/manage-settings`}>
                                    <FontAwesomeIcon icon="list" className="mr-3"/>
                                    <span className="nav-text">Management Setting</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink className="prepend-icon" to={`/vac-locations/${this.props.vacLocation.ID}/settings/schedmanage-settings`}>
                                    <FontAwesomeIcon icon="table" className="mr-3"/>
                                    <span className="nav-text">{`${this.props.vacLocation.courtDisplayName} & Time`}</span>
                                </NavLink>
                            </li>
                        </ul>
                    </li>
                </ul>
            </>
        );
    }
}


const mapStateToProps = (reducer) => {

    const {user, vacLocation} = reducer;

    return {user, vacLocation}
};


export default withRouter(connect(mapStateToProps, {})(SidebarLocationContent));

