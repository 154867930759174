
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {NavLink, withRouter} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const mainMenus = {
    'dashboard': 'Dashboard',
    'patients': 'Patients',
    'settings': 'Settings'
}

class SidebarCountyContent extends Component {

    componentDidMount() {

        const menuLi = document.getElementsByClassName('sub-menu-title');

        for (let i = 0; i < menuLi.length; i++) {

            menuLi[i].onclick = function (event) {

                this.parentElement.classList.toggle('open');
            }
        }

        this.checkOpenMenu(this.props)
    }

    checkOpenMenu(props){

        let urlNames = props.history.location.pathname.split('/')

        if (urlNames.length < 3){

            return;
        }

        const menuLi = document.getElementsByClassName('sub-menu-title');

        for (let i = 0; i < menuLi.length; i++) {

            let flag = false;

            for (let j = 0; j < menuLi[i].childNodes.length; j++) {

                if (menuLi[i].childNodes[j].className === "nav-text" && menuLi[i].childNodes[j].textContent === mainMenus[urlNames[1]]) {

                    flag = true;

                    break;
                }        
            }

            if (flag && !menuLi[i].parentElement.classList.contains('open')){

                menuLi[i].parentElement.classList.add('open')
            }
        }
    }
    
    render() {
        return (
            <ul className="nav-menu">
                <li className="menu no-arrow">
                    <NavLink to="/dashboard">
                        <FontAwesomeIcon icon="chart-area" className="mr-3"/>
                        <span className="nav-text">Dashboard</span>
                    </NavLink>
                </li>
                <li className="ui_tooltip menu">
                    <a role="button" href='null' onClick={e => e.preventDefault()} className="sub-menu-title">
                        <FontAwesomeIcon icon="users" className="mr-3"/>
                        <span className="nav-text">Demographic</span>
                    </a>
                    <ul className="sub-menu">
                        <li>
                            <NavLink className="prepend-icon" to="/patients/list">
                                <FontAwesomeIcon icon="list" className="mr-3"/>
                                <span className="nav-text">List</span>
                            </NavLink>
                        </li>
                    </ul>
                    <ul className="sub-menu">
                        <li>
                            <NavLink className="prepend-icon" to="/patients/upload">
                                <FontAwesomeIcon icon="upload" className="mr-3"/>
                                <span className="nav-text">CSV Upload</span>
                            </NavLink>
                        </li>
                    </ul>
                </li>
                
                <li className="menu no-arrow">
                    <NavLink to="/vac-locations">
                        <FontAwesomeIcon icon="building" className="mr-3"/>
                        <span className="nav-text">Locations</span>
                    </NavLink>
                </li>
                <li className="menu no-arrow">
                    <NavLink to="/admin-messages">
                        <FontAwesomeIcon icon="envelope" className="mr-3"/>
                        <span className="nav-text">Admin Messages</span>
                    </NavLink>
                </li>
                {
                    this.props.user.setting.allowForum &&
                    <li className="menu no-arrow">
                        <NavLink to="/posts">
                            <FontAwesomeIcon icon="comments" className="mr-3"/>
                            <span className="nav-text">Patient Forum</span>
                        </NavLink>
                    </li>    
                }
                <li className="ui_tooltip menu">
                    <a role="button" href='null' onClick={e => e.preventDefault()} className="sub-menu-title">
                        <FontAwesomeIcon icon="cogs" className="mr-3"/>
                        <span className="nav-text">Settings</span>
                    </a>
                    <ul className="sub-menu">
                        <li>
                            <NavLink className="patients" to="/settings/patient_groups">
                                <FontAwesomeIcon icon="users" className="mr-3"/>
                                <span className="nav-text">Patient Groups</span>
                            </NavLink>
                        </li>
                    </ul>
                    <ul className="sub-menu">
                        <li>
                            <NavLink className="list" to="/settings/manage-settings">
                                <FontAwesomeIcon icon="cog" className="mr-3"/>
                                <span className="nav-text">Management Settings</span>
                            </NavLink>
                        </li>
                    </ul>
                </li>
            </ul>
        );
    }
}


const mapStateToProps = (reducer) => {

    const {user} = reducer;

    return {user}
};


export default withRouter(connect(mapStateToProps, {})(SidebarCountyContent));

